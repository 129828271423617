<template>
  <main class="bg-gray-100 ">

    <!-- Cover Image with Text Banner -->
    <div class="h-screen items-center animate__animated animate__fadeIn">
      <div class=" bg-fixed bg-cover w-full h-full servicepg">   
        <div class="container m-auto animate__animated animate__fadeIn animate__delay-1s">  
          <div class="items-center absolute bottom-0">
            <h1 class="text-4xl md:text-6xl lg:text-9xl text-center bg-clip-text text-white tracking-wider uppercase">Our Services</h1>
            <p class="text-sm md:text-md lg:text-lg text-center font-medium text-white p-1 py-5 rounded-3xl tracking-wide">
              Accelerate your journey towards digital transformation with Qualon, a renowned software company in Sri Lanka. We provide turn-key software development services that add value to your business. We develop simple to complex business applications, building all types of custom and platform-based solutions and providing a comprehensive set of end-to-end IT solutions.
            </p>
          </div>             
        </div>
      </div>
    </div>

    <!-- Service Area -->

    <div class="bg-fixed py-20 bg-white">
    
      <div class="container m-auto grid md:grid-cols-12 items-start">         

        <div v-for="(service, index) in services" :key="index" class="service-card md:col-span-6 lg:col-span-4">
          <div class="p-2 transform hover:scale-110 duration-700 cursor-pointer">
            <!-- <lottie-player :src="require('../assets/images/' + service.src + '.json')" background="transparent"  speed="0.3"  style="width: auto;"  loop  autoplay></lottie-player> -->
            <img :src="require('../assets/images/' + service.src + '.svg' )" alt="" loading="lazy" class="block overflow-hidden w-auto m-auto">
            <h1 class="text-2xl font-semibold tracking-wider p-5 text-primary"> {{ service.title }}</h1>            
            <p class="text-left mx-1 text-md font-medium lg:px-8"> {{ service.description }} </p>
          </div>
        </div>
          
      </div>  
    </div>


  </main>
</template>


<script>

export default {
    data: () => {
        return {
            services: [
                {
                    title: 'Website Design',                  
                    src: 'website_design',
                    description: 'Web designing is more than just an attractive and clean looking front end. Qualon specializes in designing websites with a complete strategy that is made to turn your visitors into customers. Our web experiences are high-performing, designed to be user-friendly, and able to scale easily as your business grows.'
                },
                {
                    title: 'Website Development',
                    src: 'website_development',
                    description: 'As a full-stack web development company, our web development consultants provide a wide variety of web solutions for small and big businesses in different domains. We can make the final outcome of the projects go beyond your expectations and satisfy the specific needs of your business.'
                },
                {
                    title: 'eCommerce Platform',
                    src: 'ecommerce_platform',
                    description: 'We provide eCommerce development services that are customer-driven and create e-shopping experiences to deliver business results beyond your expectations. Further, we help your brand navigate through any e-commerce niche.'
                },
                {
                    title: 'Search Engine Optimization',
                    src: 'search_engine_optimization',
                    description: 'With experienced SEO specialists, we deliver websites that captivate their visitors, reach high SEO rates, and get chosen on top spots of organic search results.  We increase online brand presence fast and build your website authority for outranking your competitors.'
                },
                {
                    title: 'Web Application Development',
                    src: 'web_application_development',
                    description: 'We focus on creating unique interactive web applications that deliver your desired business outcomes. You’ll get a dedicated development team backing you up round-the-clock. We adhere to build web applications designed to reduce the cost of operations, address business challenges, and offer you a competitive edge.'
                },
                {
                    title: 'User Interface Design',
                    src: 'user_interface_design',
                    description: 'We focus user interface designing on creating visually appealing digital experiences that attract and retain users on your platform. Qualon always tries to design UI/UX solutions that are tailored to your audiences or customer base, which makes the conversion rate grow and reduces the bounce rate.'
                },              
                {
                    title: 'User Experience Optimization',
                    src: 'user_experience_optimization',
                    description: 'Users expect seamless experiences throughout all devices. Our design experts will recommend the best possible user experiences for your digital solutions that keep consumers interested and engaged. From wireframes to prototypes, we help you along your user experience journey.'
                },
                {
                    title: 'Mobile Application Development',
                    src: 'mobile_application_development',
                    description: 'Qualon has extensive experience in understanding your business requirements and developing high performing, feature-packed native mobile applications for iOS and Android devices. Our team will create practical and seamless experiences on any device and help your business get the best out of mobile technology.'
                }
            ]
        }
    },
}
</script>